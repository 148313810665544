<template>
    <div>
        <v-dialog
            v-model="$store.state.dialog"
            :fullscreen="isfullscreen"
            width="800"
            persistent
            transition="dialog-top-transition"
        >
            <v-card flat class="card">
                <v-toolbar outlined flat color="grey lighten-4">
                    <v-toolbar-title>{{$store.state.itemDetails.dialogType == 'add' ? 'إضافة' : 'تعديل'}} {{this.$route.meta.single}}</v-toolbar-title>
                    <v-spacer />
                    <v-btn icon @click="isfullscreen = !isfullscreen">
                        <v-icon small>{{ isfullscreen ? "mdi-arrow-collapse" : "mdi-arrow-expand" }}</v-icon>
                    </v-btn>
                    <v-btn @click="close()" icon>
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
                    <v-container>
                        <v-card outlined>
                            <v-container>
                                <v-row>
                                    <!-- titel -->
                                    <v-col cols="12" md="12">
                                        <h4 class="mb-2">
                                            عنوان او وصف للعرض
                                            <span class="required">*</span>
                                        </h4>
                                        <v-text-field
                                            v-model="item.titel"
                                            placeholder="وصف العرض"
                                            color="info"
                                            :rules="[$global.state.required()]"
                                            required
                                            filled
                                            outlined
                                            hide-details="auto"
                                        />
                                    </v-col>

                                    <!-- productId  -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">
                                            المنتـج
                                            <span class="required">*</span>
                                        </h4>
                                        <v-autocomplete
                                            clearable
                                            v-model="item.productId"
                                            item-text="name"
                                            item-value="id"
                                            :items="$global.state.products"
                                            :rules="[v => !!v || 'هذا الحقل مطلوب']"
                                            placeholder="المنتـج"
                                            :loading="$global.state.loading"
                                            no-data-text="لا توجد بيانات"
                                            hide-details="auto"
                                            outlined
                                            color="info"
                                            required
                                            filled
                                        />
                                    </v-col>

                                    <!-- offerValue -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">
                                            قيمة العرض ٪
                                            <span class="required">*</span>
                                        </h4>
                                        <v-text-field
                                            v-model="item.offerValue"
                                            :rules="[$global.state.maxNumber(100), $global.state.minNumber(0)]"
                                            suffix="%"
                                            type="number"
                                            :min="0"
                                            :max="100"
                                            outlined
                                            filled
                                            color="info"
                                            hide-details="auto"
                                            required
                                        ></v-text-field>
                                    </v-col>

                                    <!-- expiryDate -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">
                                            تاريخ انتهاء العرض
                                            <span class="required">*</span>
                                        </h4>
                                        <v-menu
                                            ref="menuexpiryDate"
                                            v-model="menuexpiryDate"
                                            :close-on-content-click="false"
                                            :return-value.sync="expiryDate"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="expiryDate"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    outlined
                                                    filled
                                                    hide-details="auto"
                                                    color="info"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                class="mt-"
                                                v-model="expiryDate"
                                                no-title
                                                color="secondary"
                                                scrollable
                                            >
                                                <v-btn
                                                    text
                                                    color="success"
                                                    @click="$refs.menuexpiryDate.save(expiryDate)"
                                                >موافق</v-btn>
                                                <v-btn
                                                    text
                                                    color="error"
                                                    @click="menuexpiryDate = false"
                                                >الغاء</v-btn>
                                                <v-spacer></v-spacer>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <!-- typeOffer -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">نوع العرض</h4>
                                        <div class="border-">
                                            <v-radio-group
                                                v-model="typeOffer"
                                                row
                                                class="font-weight-bold"
                                            >
                                                <v-radio
                                                    color="info"
                                                    label="كل الزبائن"
                                                    :value="false"
                                                ></v-radio>
                                                <v-radio
                                                    color="info"
                                                    label="زيون معين"
                                                    :value="true"
                                                ></v-radio>
                                            </v-radio-group>
                                        </div>
                                    </v-col>

                                    <v-col cols="12" md="12" v-if="typeOffer">
                                        <h4 class="mb-2">
                                            الزبون الموجه له العرض
                                            <span class="required">*</span>
                                        </h4>
                                        <v-autocomplete
                                            clearable
                                            v-model="item.userId"
                                            item-text="fullName"
                                            item-value="id"
                                            :items="$global.state.listClients"
                                            :rules="[v => !!v || 'هذا الحقل مطلوب']"
                                            placeholder="اختار الزبون"
                                            :loading="$global.state.loading"
                                            no-data-text="لا توجد بيانات"
                                            hide-details="auto"
                                            outlined
                                            color="info"
                                            required
                                            filled
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        <!-- actions -->
                        <v-col cols="12" md="12">
                            <v-row class="mt-2">
                                <v-btn
                                    type="submit"
                                    :loading="$global.state.loading"
                                    x-large
                                    color="secondary--text primary"
                                    class="btn"
                                    :disabled="!valid"
                                >حفظ</v-btn>
                                <v-btn
                                    @click="close()"
                                    x-large
                                    color="error"
                                    class="mr-2 btn"
                                    outlined
                                >إلغاء</v-btn>
                            </v-row>
                        </v-col>
                    </v-container>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    components: {},

    data() {
        return {
            valid: false,
            isfullscreen: false,
            item: {},
            menuexpiryDate: false,
            expiryDate: new Date().toISOString().split("T")[0],
            typeOffer: false,
        };
    },

    mounted() {
        this.reset();
        if (this.$global.state.products == "") this.getProducts();
        if (this.$global.state.listClients == "") this.getListClients();
        this.$eventBus.$on("fill-fields", () => {
            this.item = this.$store.state.itemDetails;
            if (this.$store.state.itemDetails.user != null) {
                this.typeOffer = true;
                this.item.userId = this.$store.state.itemDetails.user.id;
            }
            else this.typeOffer = false;
            this.item.productId = this.$store.state.itemDetails.product.id;
            this.expiryDate = new Date(this.$store.state.itemDetails.expiryDate).toISOString().split("T")[0];
        });
    },

    methods: {
        reset() {
            this.item = {
                titel: "",
                offerValue: 0,
                expiryDate: "",
                productId: "",
                userId: "",
            };
        },

        submit() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                if (this.$store.state.itemDetails.dialogType == "add") {
                    this.addItem();
                } else {
                    this.editItem();
                }
            }
        },

        async addItem() {
            this.$global.state.loading = true;
            if (!this.typeOffer) this.item.userId = null;
            this.item.expiryDate = this.expiryDate;
            await this.$http
                .post(`${this.$route.meta.endPoint}`, this.item)
                .then((res) => {
                    this.$eventBus.$emit(`refresh`);
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `${res.data.message}`,
                        type: "success",
                    });
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        editItem() {
            this.$global.state.loading = true;
            if (!this.typeOffer) this.item.userId = null;
            this.item.expiryDate = this.expiryDate;
            this.$http
                .put(`${this.$route.meta.endPoint}/${this.item.id}`, this.item)
                .then((res) => {
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `${res.data.message}`,
                        type: "success",
                    });
                    this.$eventBus.$emit(`refresh`);
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        getProducts() {
            this.$global.dispatch(`getProducts`);
        },

        getListClients() {
            this.$global.dispatch(`getListClients`);
        },

        close() {
            this.$store.commit("setDialog");
            this.reset();
            this.resetValidation();
            this.$store.commit("setItemDetails", {});
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>


<style scoped>
.btn-delete-file {
    padding: 11px;
    font-weight: bold;
}
</style>
